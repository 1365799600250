import React, { useEffect, useMemo, useState } from 'react';
import { Pagination, PaginationItem } from '@material-ui/lab';
import _ from 'lodash';
import {
  Container,
  CustomTable,
  HeaderItem,
  PaginationWrapper,
  Row,
  RowItem,
  Orderable,
  Footer,
  FooterRow,
  FooterItem,
  SubRowItem,
  TableHead,
  TableBody,
} from './styles';
import ArrowDown from '../../../assets/downArrow';
import { Props, SortOptions, TableHeader } from './types';

const ROWS_PER_PAGE = 20;

export const Table: React.FC<Props> = ({ columns, data, footer, onRowClick, subItensColSpan = [], pageRef }) => {
  const [page, setPage] = useState(1);
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    key: '',
    type: undefined,
  });

  useEffect(() => {
    const ref = pageRef;
    if (ref) {
      ref.current = page;
    }
  }, [page]);

  const dataPaginated = useMemo(() => {
    const orderableArr = sortOptions.type ? _.orderBy(data, sortOptions.key, sortOptions.type) : data;
    let arr = [];
    arr = orderableArr.slice((page - 1) * ROWS_PER_PAGE, (page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE);
    return arr;
  }, [data, page, sortOptions]);

  const onClickOrderable = (item: TableHeader) => {
    // Já clickou antes
    if (item.key === sortOptions.key) {
      switch (sortOptions.type) {
        case 'asc':
          setSortOptions({
            key: item.key,
            type: 'desc',
          });
          break;
        case 'desc':
          setSortOptions({
            key: '',
            type: undefined,
          });
          break;
        default:
          setSortOptions({
            key: item.key,
            type: 'asc',
          });
          break;
      }
      return;
    }
    // Primeiro Click
    setSortOptions({
      key: item.key,
      type: 'asc',
    });
  };

  const renderHeaderItem = (item: TableHeader) => {
    if (item.orderable) {
      const arrowDirection = item.key === sortOptions.key ? sortOptions.type : undefined;
      return (
        <Orderable onClick={() => onClickOrderable(item)} arrowDirection={arrowDirection}>
          {item.label}
          <ArrowDown click={null} color="white" />
        </Orderable>
      );
    }
    return item.label;
  };

  const renderRowItem = (item: any) => {
    return columns.map((header, i) => <RowItem key={i}>{item[header.key]}</RowItem>);
  };

  const renderSubRowItem = (subItems: any) => {
    let currentCollumn = 0;

    return columns.map((header, i) => {
      if (currentCollumn < columns.length) {
        currentCollumn += subItensColSpan[i] ?? 1;
        return (
          <SubRowItem colSpan={subItensColSpan[i] ?? 1} key={i}>
            {subItems[i] ? subItems[i] : ''}
          </SubRowItem>
        );
      }
      return null; // Retorna null para evitar a renderização de colunas vazias
    });
  };

  return (
    <Container>
      <CustomTable>
        <TableHead>
          <tr>
            {columns.map((item, i) => (
              <HeaderItem key={i}>{renderHeaderItem(item)}</HeaderItem>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {dataPaginated.map((item, i) => (
            <React.Fragment key={i}>
              <Row
                style={{
                  backgroundColor: i % 2 === 0 ? '#e1e6e7' : '#ffffff',
                  cursor: onRowClick ? 'pointer' : 'default',
                }}
                onClick={() => (onRowClick ? onRowClick(item) : null)}
              >
                {renderRowItem(item)}
              </Row>
              {item.subItems?.length > 0 && (
                <Row
                  className="subItemsContainer"
                  style={{ backgroundColor: i % 2 === 0 ? '#dfeef0' : 'whitesmoke', cursor: 'default' }}
                  onClick={() => (onRowClick ? onRowClick(item) : null)}
                >
                  {renderSubRowItem(item.subItems)}
                </Row>
              )}
            </React.Fragment>
          ))}
        </TableBody>
        {footer && (
          <Footer>
            <FooterRow>
              {footer.map((item, i) => (
                <FooterItem key={i}>{item}</FooterItem>
              ))}
            </FooterRow>
          </Footer>
        )}
      </CustomTable>
      <PaginationWrapper>
        <Pagination
          showFirstButton
          showLastButton
          count={Math.ceil(data.length / 20)}
          page={page}
          renderItem={item => (
            <PaginationItem
              {...item}
              onClick={() => {
                if (item.type === 'last') {
                  setPage(prevPage => Math.min(prevPage + 10, Math.ceil(data.length / 20))); // Avança 10 páginas, respeitando o máximo
                } else if (item.type === 'first') {
                  setPage(prevPage => Math.max(prevPage - 10, 1)); // Volta 10 páginas, respeitando o mínimo
                } else {
                  setPage(item.page);
                }
              }}
            />
          )}
        />
      </PaginationWrapper>
    </Container>
  );
};

export default Table;
