import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Container, DataGrid, DataLabel, DataRow, DataValue, TabItem, TableContainer, TabMenu } from './styles';
import { Button, Input, Select, Form, Option, PaginatedTable, Checkbox } from '../../../_core/_components';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { mascaraMesAnoDateRange } from '../../../_core/masks';
import { requests } from '../../../requests';
import { useCache } from '../../../_core/cache';
import { handleErrors, success } from '../../../_core/services/toast';
import api from '../../../_core/api';
import { DataResumeShoppings, DataResumeStores, ResumeTotal } from './types';
import { convertFromDatabaseDate, toReal } from '../../../_core/services/formaters';
import { AuthContext } from '../../../_main/contexts/auth';
import { downloadXls } from '../../../_core/services/download';

export function RelatorioInadimplencia(): any {
  const { profile, shoppings: profileShoppings } = useContext(AuthContext);

  const { response: responseFiltersShoppings, isLoading: isLoadingFiltersShoppings } = useCache({
    key: 'getAllShoppings',
    fn: requests.get('getAllShoppings'),
  });

  const { response: responseFiltersTipoMovimento, isLoading: isLoadingFiltersTipoMovimento } = useCache({
    key: 'getTipoMovimento',
    fn: requests.get('getTipoMovimento'),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [shoppingsOptions, setShoppingsOptions] = useState<any[]>([]);
  const [shopping, setShopping] = useState<Option[]>([]);
  const [portfolioOptions, setPortfolioOptions] = useState<any[]>([]);
  const [portfolio, setPortfolio] = useState<Option[]>([]);
  const [dataVencimento, setDataVencimento] = useState(() => {
    const dataAtual = new Date();
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    return `${mes}/${ano} - ${mes}/${ano}`;
  });
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [tipoContaOptions, setTipoContaOptions] = useState<any[]>([]);
  const [tipoConta, setTipoConta] = useState<any[]>([]);
  const [total, setTotal] = useState<ResumeTotal>();
  const [resumeShoppings, setResumeShoppings] = useState<DataResumeShoppings>();
  const [resumeStores, setResumeStores] = useState<DataResumeStores>();
  const [apenasParticipacao, setApenasParticipacao] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (responseFiltersShoppings !== undefined && _.isArray(profileShoppings)) {
      const profileShoppingsArr = profileShoppings.map(e => e.id);
      const portfolioList = responseFiltersShoppings.data
        .filter((item: any) => profileShoppingsArr.includes(item.id))
        .map((item: any) => ({
          value: item.portfolio,
          label: item.portfolio,
        }));
      const arrShoppingsOptions = responseFiltersShoppings.data
        .filter((item: any) => profileShoppingsArr.includes(item.id))
        .map((e: any) => ({ value: e.id, label: e.nome }));
      setShoppingsOptions(arrShoppingsOptions);
      setShopping(arrShoppingsOptions);
      const uniquePortfolioList = _.uniqBy(portfolioList, 'value');
      setPortfolioOptions(uniquePortfolioList);
    }
  }, [responseFiltersShoppings, profileShoppings]);

  useEffect(() => {
    if (responseFiltersTipoMovimento !== undefined) {
      const tipoContaList = responseFiltersTipoMovimento.data.map((item: any) => ({
        value: item.idTipoConta,
        label: item.tipoConta,
      }));
      const tipoContaOptionsArr = _.uniqBy(tipoContaList, 'value');
      setTipoContaOptions(tipoContaOptionsArr);
      setTipoConta(tipoContaOptionsArr);
    }
  }, [responseFiltersTipoMovimento]);

  const getTipoContaParams = () => {
    if (tipoConta.length > 0 && responseFiltersTipoMovimento !== undefined) {
      const idsTipoConta = tipoConta.map(e => e.value);
      return responseFiltersTipoMovimento.data
        .filter((item: any) => idsTipoConta.includes(item.idTipoConta))
        .map((e: any) => e.codigo)
        .join(',');
    }
    return undefined;
  };

  const params = useMemo(
    () => ({
      shoppingIds: shopping.map(e => e.value).join(','),
      nomeFantasia,
      mesAnoInicio: dataVencimento.length === 17 ? dataVencimento.split(' - ')[0].replace('/', '-') : undefined,
      mesAnoFim: dataVencimento.length === 17 ? dataVencimento.split(' - ')[1].replace('/', '-') : undefined,
      codigosTipoMovimento: getTipoContaParams(),
      apenasParticipacao,
    }),
    [shopping, nomeFantasia, dataVencimento, getTipoContaParams, apenasParticipacao],
  );

  const getHeader = async () => {
    const { data } = await api.get<ResumeTotal>('/inadimplencia/header', {
      params,
    });
    setTotal(data);
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      setTotal(undefined);
      setResumeShoppings(undefined);
      setResumeStores(undefined);
      await getHeader();
      const [shoppingsData, storesData] = await Promise.all([
        api.get<DataResumeShoppings>(`/inadimplencia/detalhe/shoppings`, {
          params,
        }),
        api.get<DataResumeStores>(`/inadimplencia/detalhe/lojas`, {
          params,
        }),
      ]);
      setResumeShoppings(shoppingsData.data);
      setResumeStores(storesData.data);
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPaginatedData = async (url: string, setData: Function, page = 1) => {
    setIsLoading(true);
    try {
      const { data } = await api.get(url, { params: { ...params, page } });
      setData(data);
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resumeShoppingsRender = useMemo(() => {
    if (resumeShoppings?.data) {
      return resumeShoppings.data.map(e => ({
        ...e,
        inadimplenciaBrutaPercentual: e.inadimplenciaBrutaPercentual.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        }),
        inadimplenciaLiquidaPercentual: e.inadimplenciaLiquidaPercentual.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        }),
        faturado: toReal(e.faturado),
        recebidoBruto: toReal(e.recebidoBruto),
        inadimplenciaBruta: toReal(e.inadimplenciaBruta),
        recebidoLiquido: toReal(e.recebidoLiquido),
        inadimplenciaLiquida: toReal(e.inadimplenciaLiquida),
        recebimentoTotal: toReal(e.recebimentoTotal),
      }));
    }
    return [];
  }, [resumeShoppings]);

  const resumeStoresRender = useMemo(() => {
    if (resumeStores?.data) {
      return resumeStores.data.map(e => ({
        ...e,
        inadimplenciaBrutaPercentual: e.inadimplenciaBrutaPercentual.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        }),
        inadimplenciaLiquidaPercentual: e.inadimplenciaLiquidaPercentual.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        }),
        faturado: toReal(e.faturado),
        recebidoBruto: toReal(e.recebidoBruto),
        inadimplenciaBruta: toReal(e.inadimplenciaBruta),
        recebidoLiquido: toReal(e.recebidoLiquido),
        inadimplenciaLiquida: toReal(e.inadimplenciaLiquida),
        recebimentoTotal: toReal(e.recebimentoTotal),
        dataCompensacao: e.dataCompensacao?.length > 0 ? convertFromDatabaseDate(e.dataCompensacao) : '',
        vencimento: convertFromDatabaseDate(e.vencimento),
      }));
    }
    return [];
  }, [resumeStores]);

  const onClickExportar = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`inadimplencia/detalhe/lojas/csv`, {
        responseType: 'arraybuffer',
        params,
      });
      downloadXls(response, 'loja.csv');
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    // eslint-disable-next-line no-unused-expressions
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setIsLoading(true);
      try {
        if (file) {
          const formdata = new FormData();
          formdata.append('file', file);

          await api.post(`/inadimplencia/upload/ajuste`, formdata);
          success('Arquivo enviado.');
        }
      } catch (error) {
        handleErrors(error);
      }
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const submitContainer = (
    <>
      <Button text="Pesquisar" onClick={getData} />
      <Button onClick={handleButtonClick} text="Subir Arquivo" variant="outlined" styles={{ marginLeft: '0.5rem' }} />
    </>
  );

  return (
    <Container>
      <FullScreenLoading
        isEnabled={[isLoading, isLoadingFiltersShoppings, isLoadingFiltersTipoMovimento].some(e => e)}
      />
      <Form
        items={[
          <Select
            placeholder="Shoppings"
            options={shoppingsOptions}
            state={[shopping, setShopping]}
            disabled={profile.includes('SHOPPING')}
          />,
          <Select
            placeholder="Portfólio"
            options={portfolioOptions}
            state={[portfolio, setPortfolio]}
            disabled={profile.includes('CORPORATIVA')}
          />,
          <Input label="Nome fantasia" state={[nomeFantasia, setNomeFantasia]} />,
          <Input
            label="Data (intervalo)"
            state={[dataVencimento, setDataVencimento]}
            pattern={mascaraMesAnoDateRange}
            placeholder="01/2022 - 12/2024"
          />,
          <Select placeholder="Tipo Conta" options={tipoContaOptions} state={[tipoConta, setTipoConta]} />,
          <Checkbox
            checked={apenasParticipacao}
            onClick={() => setApenasParticipacao(!apenasParticipacao)}
            label="Apenas participação"
          />,
        ]}
        submitButton={submitContainer}
      />

      <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".csv" onChange={handleFileChange} />
      {total && (
        <div style={{ width: '100%', marginTop: '1rem' }}>
          <DataGrid>
            <DataRow>
              <DataLabel>Faturado:</DataLabel>
              <DataValue>
                {total.faturado.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Recebimento Bruto:</DataLabel>
              <DataValue>
                {total.recebidoBruto.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Inadimplência Bruta (R$):</DataLabel>
              <DataValue>
                {total.inadimplenciaBruta.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Inadimplência Bruta (%):</DataLabel>
              <DataValue>
                {total.inadimplenciaBrutaPercentual.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Recebido Líquido:</DataLabel>
              <DataValue>
                {total.recebidoLiquido.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Inadimplência Líquida (R$):</DataLabel>
              <DataValue>
                {total.inadimplenciaLiquida.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Inadimplência Líquida (%):</DataLabel>
              <DataValue>
                {total.inadimplenciaLiquidaPercentual.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataLabel>Recebimento Total:</DataLabel>
              <DataValue>
                {total.recebimentoTotal.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </DataValue>
            </DataRow>
          </DataGrid>
          <TabMenu>
            <TabItem onClick={() => setTabIndex(0)} active={tabIndex === 0}>
              Visão shoppings
            </TabItem>
            <TabItem onClick={() => setTabIndex(1)} active={tabIndex === 1}>
              Visão lojas
            </TabItem>
          </TabMenu>
          <TableContainer>
            {resumeShoppings && tabIndex === 0 && (
              <PaginatedTable
                columns={[
                  { label: 'Portfólio', key: 'portfolio', orderable: true },
                  { label: 'Shopping', key: 'shopping', orderable: true },
                  { label: 'Faturado', key: 'faturado', orderable: true },
                  { label: 'Recebimento Bruto', key: 'recebidoBruto', orderable: true },
                  { label: 'Inad Bruta (R$)', key: 'inadimplenciaBruta', orderable: true },
                  { label: 'Inad Bruta (%)', key: 'inadimplenciaBrutaPercentual', orderable: true },
                  { label: 'Recebimento líquido (Recuperação)', key: 'recebidoLiquido', orderable: true },
                  { label: 'Inad Líquida (R$)', key: 'inadimplenciaLiquida' },
                  { label: 'Inad Líquida (%)', key: 'inadimplenciaLiquidaPercentual', orderable: true },
                  { label: 'Recebimento total', key: 'recebimentoTotal', orderable: true },
                ]}
                data={resumeShoppingsRender}
                pagination={resumeShoppings.pageInfo}
                onChangePage={(page, sort) =>
                  getPaginatedData(`/inadimplencia/detalhe/shoppings?${sort}`, setResumeShoppings, page)
                }
              />
            )}
            {resumeStores && tabIndex === 1 && (
              <PaginatedTable
                columns={[
                  { label: 'Tipo Conta', key: 'tipoConta', orderable: true },
                  { label: 'Portfólio', key: 'portfolio', orderable: true },
                  { label: 'Shopping', key: 'shopping', orderable: true },
                  { label: 'Nome Fantasia', key: 'nomeFantasia', orderable: true },
                  { label: 'Numero Titulo', key: 'numeroTitulo', orderable: true },
                  { label: 'Cliente', key: 'idCliente', orderable: true },
                  { label: 'CPF/CNPJ', key: 'cpfCnpj', orderable: true },
                  { label: 'LUC', key: 'luc', orderable: true },
                  { label: 'Data Vencimento', key: 'vencimento', orderable: true },
                  { label: 'Doc Pagto', key: 'docPagamento', orderable: true },
                  { label: 'Data Compensação', key: 'dataCompensacao', orderable: true },
                  { label: 'Faturado', key: 'faturado', orderable: true },
                  { label: 'Recebimento Bruto', key: 'recebidoBruto', orderable: true },
                  { label: 'Inad Bruta (R$)', key: 'inadimplenciaBruta', orderable: true },
                  { label: 'Inad Bruta (%)', key: 'inadimplenciaBrutaPercentual', orderable: false },
                  { label: 'Recebimento líquido (Recuperação)', key: 'recebidoLiquido', orderable: true },
                  { label: 'Inad Líquida (R$)', key: 'inadimplenciaLiquida', orderable: true },
                  { label: 'Inad Líquida (%)', key: 'inadimplenciaLiquidaPercentual', orderable: false },
                  { label: 'Recebimento total', key: 'recebimentoTotal', orderable: false },
                ]}
                data={resumeStoresRender}
                pagination={resumeStores.pageInfo}
                onChangePage={(page, sort) =>
                  getPaginatedData(`/inadimplencia/detalhe/lojas?${sort}`, setResumeStores, page)
                }
              />
            )}
          </TableContainer>
          {resumeStores && tabIndex === 1 && <Button text="Exportar" onClick={onClickExportar} />}
        </div>
      )}
    </Container>
  );
}
