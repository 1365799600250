import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { MoreVert, Person } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { Container, Section } from './styles';
import api from '../../../_core/api';
import { ModalContent } from './modal-content';
import { AuthContext } from '../../../_main/contexts/auth';
import { failure, handleErrors, success, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Form, Select, Button, Input, Option, Modal, Table } from '../../../_core/_components';

type Response = {
  datVencimento: string;
  fiadores: any[];
  flagNegativar: boolean;
  idBoleto: number;
  idShopping: number;
  luc: string;
  nmShopping: string;
  nomFantasia: string;
  numBoleto: number;
  numChamado: string;
  nomeAnexo: string;
  qtdNegativacao: 0;
  quantidadeExcecao: 0;
  serasaJustificatica: { idSerasaJustificatica: number; txtJustificativa: string };
  statusWorkflowSerasa: { ativo: boolean; codigo: string; id: number; nome: string };
  serasaLoja: {
    idSerasaLoja: number;
    grupoCobranca: string;
    portfolio: string;
    nomRazaoSocial: string;
  };
  txtObservacao: string;
  valTotalVencido: number;
};

export type Values = {
  id: number;
  negativado: boolean;
  fiadores: any[];
  justificativa: number;
  observacao: string;
  nrChamado: string;
  anexo: string;
};

export const ValidarAprovacoes: React.FC = () => {
  const { id, profile, shoppings: profileShoppings } = useContext(AuthContext);
  const { response: responseJustificativas, isLoading: isLoadingJustificativas } = useCache({
    key: 'getJustificativas',
    fn: requests.get('getJustificativas'),
  });

  const pageRef = useRef<number>();
  const [fiadores, setFiadores] = useState<any[]>([]);
  const [justificativas, setJustificativas] = useState<any[]>([]);
  const [shoppings, setShoppings] = useState<any[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [marca, setMarca] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [luc, setLuc] = useState('');
  const [nrBoleto, setNrBoleto] = useState('');
  const [data, setData] = useState<Response[]>([]);
  const [values, setValues] = useState<Values[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>();

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
    }
  }, [profileShoppings]);

  useEffect(() => {
    if (responseJustificativas !== undefined) {
      const listJustificativas: any[] = [];
      responseJustificativas.data.content.map((item: any) => {
        listJustificativas.push({
          value: item.idSerasaJustificatica,
          label: item.txtJustificativa,
          campoObrigatorio: item.campoObrigatorio,
        });
        return null;
      });
      setJustificativas(listJustificativas);
    }
  }, [responseJustificativas]);

  const getShoppingIds = () => {
    return selectedShoppings.find(s => s.value === 'todos')
      ? shoppings.filter(s => s.value !== 'todos').map(e => e.value)
      : selectedShoppings.map(e => e.value);
  };

  const getFilters = async (page?: number) => {
    setLoading(true);
    setData([]);
    try {
      const pageQueryString = page ? `?page=${page}` : '';
      const response = await api.post(`/workflow/serasa/serasa-negativacao/buscar/validar-boletos${pageQueryString}`, {
        idShopping: getShoppingIds(),
        idUsuario: id,
        luc: luc.length > 0 ? luc : undefined,
        numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
        numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
        status: ['PENDENTE_APROVACAO_EXCECAO_KA'],
      });
      if (_.isEmpty(response.data.content)) {
        warning('Não obteve resultados.');
      } else {
        setValues(
          response.data.content.map((item: Response) => ({
            id: item.idBoleto,
            negativado: item.flagNegativar,
            fiadores: item.fiadores.map(fiador => ({
              id: fiador.usuarioCadastro.id,
              label: fiador.usuarioCadastro.nome,
            })),
            justificativa: item.serasaJustificatica?.idSerasaJustificatica ?? 999,
            observacao: item.txtObservacao ?? '',
            nrChamado: item.numChamado ?? '',
            valTotalVencido: item.valTotalVencido,
            anexo: item.nomeAnexo ?? '',
          })),
        );
        setData(response.data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const submit = async () => {
    let disabled = false;
    let boletoId = 0;
    values.forEach(e => {
      if (!e.negativado && (!e.justificativa || e.justificativa === 999)) {
        disabled = true;
        boletoId = e.id;
      }
    });
    if (disabled) {
      const boleto = data.find(e => e.idBoleto === boletoId);
      return failure(`O boleto ${boleto?.numBoleto} precisa de uma justificativa.`);
    }
    setLoading(true);
    try {
      const boletos = data.map((item, i) => ({
        idBoleto: item.idBoleto,
        loja: {
          idSerasaLoja: item.serasaLoja.idSerasaLoja,
          negativar: values[i].negativado,
        },
        fiadores: item.fiadores.map(e => ({
          idSerasaBoletoFiador: e.idSerasaBoletoFiador,
          negativar: values[i].negativado,
        })),
      }));
      await api.post('/workflow/serasa/serasa-negativacao/submeter-negativacao', {
        idUsuario: id,
        boletos,
      });
      await getFilters();
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
    return null;
  };

  const limparObservacoes = async () => {
    setLoading(true);
    try {
      await api.post('/workflow/serasa/serasa-workflow/historico/limpar', {
        idBoleto: selectedValue.id,
        idUsuario: id,
      });
      await getFilters(pageRef.current);
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setSelectedValue(undefined);
    setLoading(false);
  };

  const renderSubItems = (item: any) => {
    const arr = [];
    if (item.serasaJustificatica?.txtJustificativa) {
      arr.push(<b>Justificativa: </b>);
      arr.push(item.serasaJustificatica.txtJustificativa);
    }
    if (item.numChamado) {
      arr.push(<b>Nr. do chamado: </b>);
      arr.push(item.numChamado);
    }
    if (item.txtObservacao) {
      arr.push(<b>Observação: </b>);
      arr.push(item.txtObservacao);
    }
    return arr.length > 0 ? arr : undefined;
  };

  const download = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-negativacao/download/boletos`,
        {
          idShopping: getShoppingIds(),
          idUsuario: id,
          luc: luc.length > 0 ? luc : undefined,
          numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
          numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
          status: ['PENDENTE_APROVACAO_EXCECAO_KA'],
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const findIdBoleto = (idBoleto: number) => {
    const boleto = data.find(e => e.idBoleto === idBoleto);
    if (boleto) {
      setSelectedValue({
        id: boleto.idBoleto,
        justificativa: boleto.serasaJustificatica?.idSerasaJustificatica ?? 999,
        observacao: boleto.txtObservacao ?? '',
        nrChamado: boleto.numChamado ?? '',
        anexo: boleto.nomeAnexo ?? '',
      });
    }
  };

  const onModalSubmit = async () => {
    setLoading(true);
    if (selectedValue === undefined) return;
    try {
      if (selectedValue.file) {
        const formData = new FormData();
        formData.append('file', selectedValue.file);
        formData.append('idBoleto', selectedValue.id.toString());
        formData.append('idJustificativa', selectedValue.justificativa.toString());
        formData.append('idUsuario', id.toString());
        formData.append('numChamado', selectedValue.nrChamado);
        formData.append('txtObservacao', selectedValue.observacao);
        await api.post('/workflow/serasa/serasa-upload/historico/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        const payload = {
          idBoleto: selectedValue.id,
          idJustificativa: selectedValue.justificativa,
          idUsuario: id,
          numChamado: selectedValue.nrChamado,
          txtObservacao: selectedValue.observacao,
        };
        await api.post('/workflow/serasa/serasa-workflow/salvar-historico', payload);
      }
      setSelectedValue(undefined);
      getFilters();
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const columns = useMemo(() => {
    const columns1 = [
      { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
      { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
      { label: 'Razão Social', key: 'nomRazaoSocial', orderable: true },
    ];
    const columns2 = [
      { label: 'LUC', key: 'luc', orderable: true },
      { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
      { label: 'Número do boleto', key: 'numBoleto', orderable: true },
      { label: 'Valor do Boleto', key: 'valTotalVencido', orderable: true },
      { label: 'Qtd de negativações', key: 'qtdNegativacao', orderable: true },
      { label: 'Qtd de exceções', key: 'quantidadeExcecao', orderable: true },
      { label: 'Negativar', key: 'negativar', orderable: false },
      { label: 'Fiadores', key: 'fiadores', orderable: false },
      { label: 'Justificativa', key: 'add', orderable: false },
    ];
    if (profile === 'COBRANÇA CORPORATIVA') {
      return [
        ...columns1,
        { label: 'Grupo de cobrança', key: 'grupoCobranca', orderable: true },
        { label: 'Portfolio', key: 'portfolio', orderable: true },
        ...columns2,
      ];
    }
    return [...columns1, ...columns2];
  }, [profile]);

  const renderedData = () => {
    return data.map(item => ({
      nmShopping: item.nmShopping,
      nomFantasia: item.nomFantasia,
      nomRazaoSocial: item.serasaLoja.nomRazaoSocial,
      luc: item.luc,
      datVencimento: item.datVencimento,
      numBoleto: item.numBoleto,
      valTotalVencido: item.valTotalVencido?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      qtdNegativacao: item.qtdNegativacao,
      quantidadeExcecao: item.quantidadeExcecao,
      grupoCobranca: item.serasaLoja.grupoCobranca,
      portfolio: item.serasaLoja.portfolio,
      fiadores: item.fiadores.length ? (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="primary"
          component="span"
          onClick={() => setFiadores(item.fiadores)}
        >
          <Person />
        </IconButton>
      ) : null,
      negativar: item.flagNegativar ? (
        <span style={{ backgroundColor: '#FFF6E8', color: '#E88D03', padding: 8, borderRadius: 4 }}>NEGATIVAR</span>
      ) : (
        <span style={{ backgroundColor: '#ECFBE6', color: '#038931', padding: 8, borderRadius: 4 }}>ISENTAR</span>
      ),
      add: (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="primary"
          component="span"
          onClick={() => findIdBoleto(item.idBoleto)}
        >
          <MoreVert />
        </IconButton>
      ),
      subItems: renderSubItems(item),
    }));
  };

  const modalContentFiador = useMemo(() => {
    const arrayInput: React.ReactNode[] = [];
    fiadores.forEach(fiador => {
      arrayInput.push(<Input label="Nome" state={[fiador.nomeFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="CPF/CNPJ" state={[fiador.cpfcnpjFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="Endereço" state={[fiador?.endereco ?? '', () => null]} disabled />);
    });
    return <Form items={arrayInput} />;
  }, [fiadores]);

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoadingJustificativas} />
      <Container>
        <Form
          items={[
            <Select
              todos
              checkedByDefault
              placeholder="Shoppings"
              state={[selectedShoppings, setSelectedShoppings]}
              options={shoppings}
            />,
            <Input label="CNPJ/CPF" state={[cnpj, setCnpj]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Marca" state={[marca, setMarca]} />,
            <Input label="Número do boleto" state={[nrBoleto, setNrBoleto]} />,
          ]}
          submitButton={<Button onClick={() => getFilters()} text="Pesquisar" />}
        />
        {renderedData().length > 0 && (
          <>
            <Table columns={columns} data={renderedData()} pageRef={pageRef} />
            <Section>
              <Button text="Aprovar e submeter negativação" onClick={submit} />
              <Button text="Exportar" onClick={download} />
            </Section>
          </>
        )}
      </Container>
      {selectedValue && (
        <Modal
          title="Justificativa"
          onClose={() => setSelectedValue(undefined)}
          content={<ModalContent valuesState={[selectedValue, setSelectedValue]} justificativas={justificativas} />}
          actionItems={[
            <Button text="Limpar justificativa" onClick={limparObservacoes} />,
            <Button text="Salvar" onClick={onModalSubmit} />,
          ]}
        />
      )}
      {fiadores.length > 0 && (
        <Modal title="Detalhes do Fiador" onClose={() => setFiadores([])} content={modalContentFiador} />
      )}
    </>
  );
};
