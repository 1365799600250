/* eslint-disable react/jsx-curly-newline */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import * as S from './styles';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import Table from '../../../_core/_components/table';
import api from '../../../_core/api';
import { handleErrors, success, warning } from '../../../_core/services/toast';
import { Form, Select, Button, Input, Option, Checkbox, Modal, SingleSelect } from '../../../_core/_components';
import { AuthContext } from '../../../_main/contexts/auth';
import ConfirmationModal from '../../../_core/_components/confirmation-modal';
import { mascaraDateRange } from '../../../_core/masks';
import { ModalContent } from './modal-content';

type StatusSerasaDTO = {
  content: any[];
  datetime: string;
  error: boolean;
  message: string;
};

const CONFIRMATION_MODAL_TEXT = [
  'Deseja excluir os itens selecionados?',
  'Enviar para o Serasa os itens selecionados?',
];

export const ReenvioRetornoErro: React.FC = () => {
  const { id: idUsuario, shoppings: profileShoppings } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [operacao, setOperacao] = useState<Option>({ value: 'INCLUSAO', label: 'INCLUSÃO' });
  const [nomFantasia, setNomFantasia] = useState('');
  const [luc, setLuc] = useState('');
  const [nrBoleto, setNrBoleto] = useState('');
  const [result, setResult] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [details, setDetails] = useState<any>();
  const [confirmationModalText, setConfirmationModalText] = useState<string>();
  const [dataVencimento, setDataVencimento] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
    }
  }, [profileShoppings]);

  const getShoppingIds = () => {
    return selectedShoppings.find(s => s.value === 'todos')
      ? shoppings.filter(s => s.value !== 'todos').map(e => e.value)
      : selectedShoppings.map(e => e.value);
  };

  const getStatusSerasa = async () => {
    setLoading(true);
    setResult([]);
    try {
      const { data } = await api.post<StatusSerasaDTO>(`/workflow/serasa/serasa-negativacao/buscar/erro-negativados`, {
        idShopping: getShoppingIds(),
        luc: luc.length > 0 ? luc : undefined,
        nomFantasia,
        numBoleto: nrBoleto,
        operacao: operacao.value,
        dataStatusIni:
          dataVencimento.length === 23
            ? moment(dataVencimento.split(' - ')[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
            : undefined,
        dataStatusFim:
          dataVencimento.length === 23
            ? moment(dataVencimento.split(' - ')[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
            : undefined,
      });
      if (_.isEmpty(data.content)) {
        warning('Não obteve resultados.');
      } else {
        setResult(data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const handleDate = (value: string) => {
    if (value) {
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return '';
  };

  const checkItemSelection = (idSerasaBoletoIdPessoa: string) => selectedData.includes(idSerasaBoletoIdPessoa);

  const clickRow = (idSerasaBoletoIdPessoa: string) => {
    const isSelected = checkItemSelection(idSerasaBoletoIdPessoa);

    if (!isSelected) {
      setSelectedData([...selectedData, idSerasaBoletoIdPessoa]);
    } else {
      setSelectedData(selectedData.filter(id => id !== idSerasaBoletoIdPessoa));
    }
  };

  const bToggleAll = useMemo(() => {
    const allBoletosIds = result.map(e => `${e.idSerasaBoleto}_${e.idPessoa}`);
    return allBoletosIds.every(e => selectedData.includes(e));
  }, [selectedData, result]);

  const onToggleAll = () => {
    if (bToggleAll) {
      setSelectedData([]);
    } else {
      setSelectedData(result.map(e => `${e.idSerasaBoleto}_${e.idPessoa}`));
    }
  };

  const renderedData = useMemo(() => {
    if (result.length > 0) {
      return result.map(item => ({
        idSerasaBoleto: item.idSerasaBoleto,
        idPessoa: item.idPessoa,
        toggle: (
          <Checkbox
            checked={checkItemSelection(`${item.idSerasaBoleto}_${item.idPessoa}`)}
            onClick={() => clickRow(`${item.idSerasaBoleto}_${item.idPessoa}`)}
          />
        ),
        nmShopping: item.shopping.nomeShopping,
        nomFantasia: item.nomFantasia,
        nomRazaoSocial: item.nomRazaoSocial,
        luc: item.luc,
        numBoleto: item.numBoleto,
        numBoletoOriginal: item.numBoletoOriginal,
        datVencimento: handleDate(item.datVencimento),
        valTotalVencido: item.valTotalVencido?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        documentoDevedor: item.documentoDevedor,
        tipoPessoa: item.tipoPessoa,
        operacao: item.operacao,
        nome: item.statusWorkflowSerasa.nome,
        status: item.retornoSerasa.status,
        dataRetornoSerasa: handleDate(item.retornoSerasa.dataRetornoSerasa),
        detalhes: item.retornoSerasa.erros?.length ? (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            onClick={() =>
              setDetails({
                errors: item.retornoSerasa.erros?.map((err: any) => err.description).join(' / '),
                idSerasaBoleto: item.idSerasaBoleto,
                idPessoa: item.idPessoa,
                tipoPessoa: item.tipoPessoa,
              })
            }
          >
            <MoreIcon />
          </IconButton>
        ) : null,
      }));
    }
    return [];
  }, [result, selectedData]);

  const handleConfirmConfirmationModal = () => {
    if (confirmationModalText === CONFIRMATION_MODAL_TEXT[0]) {
      return exclude();
    }
    return enviarNegativacao();
  };

  const exclude = async () => {
    setLoading(true);
    try {
      setConfirmationModalText(undefined);
      const idBoletos = selectedData.map(e => e.split('_')[0]);
      await api.post(`workflow/serasa/serasa-negativacao/erro-negativados/isentar`, {
        idBoletos,
        idUsuario,
      });
      success('Excluído com sucesso.');
      const { data } = await api.post<StatusSerasaDTO>(`/workflow/serasa/serasa-negativacao/buscar/erro-negativados`, {
        idShopping: getShoppingIds(),
        luc: luc.length > 0 ? luc : undefined,
        nomFantasia,
        numBoleto: nrBoleto,
        operacao: operacao.value,
        dataStatusIni:
          dataVencimento.length === 23
            ? moment(dataVencimento.split(' - ')[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
            : undefined,
        dataStatusFim:
          dataVencimento.length === 23
            ? moment(dataVencimento.split(' - ')[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
            : undefined,
      });
      setResult(data.content);
      setSelectedData([]);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const enviarNegativacao = async () => {
    setLoading(true);
    try {
      const boletos = renderedData
        .filter(item => selectedData.includes(`${item.idSerasaBoleto}_${item.idPessoa}`))
        .map(item => ({
          idSerasaBoleto: item.idSerasaBoleto,
          idPessoa: item.idPessoa,
          tipoPessoa: item.tipoPessoa,
          operacao: item.operacao,
        }));
      const payload = { idUsuario, boletos };
      await api.post('/workflow/serasa/serasa-negativacao/reenviar-boletos', payload);
      const { data } = await api.post<StatusSerasaDTO>(`/workflow/serasa/serasa-negativacao/buscar/erro-negativados`, {
        idShopping: getShoppingIds(),
        luc: luc.length > 0 ? luc : undefined,
        nomFantasia,
        numBoleto: nrBoleto,
        operacao: operacao.value,
        dataStatusIni:
          dataVencimento.length === 23
            ? moment(dataVencimento.split(' - ')[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
            : undefined,
        dataStatusFim:
          dataVencimento.length === 23
            ? moment(dataVencimento.split(' - ')[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
            : undefined,
      });
      setSelectedData([]);
      setResult(data.content);
      setConfirmationModalText(undefined);
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      {confirmationModalText && (
        <ConfirmationModal
          text={confirmationModalText}
          handleCancel={() => setConfirmationModalText(undefined)}
          handleOk={handleConfirmConfirmationModal}
          open
        />
      )}
      <S.Container>
        <Form
          items={[
            <Select
              checkedByDefault
              todos
              placeholder="Shoppings"
              options={shoppings}
              state={[selectedShoppings, setSelectedShoppings]}
            />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Status" state={[status, setStatus]} />,
            <Input label="Nome fantasia" state={[nomFantasia, setNomFantasia]} />,
            <Input label="Número do boleto" state={[nrBoleto, setNrBoleto]} />,
            <Input
              label="Data vencimento (intervalo)"
              state={[dataVencimento, setDataVencimento]}
              pattern={mascaraDateRange}
              placeholder="01/01/2022 - 31/12/2024"
            />,
            <SingleSelect
              placeholder="Operação"
              state={[operacao, option => setOperacao(option!)]}
              options={[
                { value: 'INCLUSAO', label: 'INCLUSÃO' },
                { value: 'EXCLUSAO', label: 'EXCLUSÃO' },
              ]}
              isClearable={false}
            />,
          ]}
          submitButton={<Button onClick={getStatusSerasa} text="Pesquisar" />}
        />

        {result.length > 0 && (
          <>
            <Table
              columns={[
                { label: <Checkbox type="secondary" checked={bToggleAll} onClick={onToggleAll} />, key: 'toggle' },
                { label: 'Shopping', key: 'nmShopping', orderable: true },
                { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
                { label: 'Razão Social', key: 'nomRazaoSocial', orderable: true },
                { label: 'LUC', key: 'luc', orderable: true },
                { label: 'Número do boleto', key: 'numBoletoOriginal', orderable: true },
                { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
                { label: 'Valor boleto', key: 'valTotalVencido', orderable: true },
                { label: 'Tipo pessoa', key: 'tipoPessoa', orderable: true },
                { label: 'Operação', key: 'operacao', orderable: true },
                { label: 'Data retorno', key: 'dataRetornoSerasa', orderable: true },
                { label: 'Detalhes', key: 'detalhes' },
              ]}
              data={renderedData}
            />
            <S.Actions>
              <Button text="Excluir" onClick={() => setConfirmationModalText(CONFIRMATION_MODAL_TEXT[0])} />
              <Button
                text="Enviar para negativação"
                onClick={() => setConfirmationModalText(CONFIRMATION_MODAL_TEXT[1])}
              />
            </S.Actions>
            {details && (
              <Modal
                title="Mensagem de erro"
                onClose={() => setDetails(undefined)}
                content={<ModalContent details={details} />}
              />
            )}
          </>
        )}
      </S.Container>
    </>
  );
};
