import React, { useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { Checkbox, Form, Input, SingleSelect } from '../../../../_core/_components';
import { EditUnidadeEconomicaProperty } from './reducer';

type Props = {
  empresas: any[];
  form: any;
  dispatch: React.Dispatch<{
    type: EditUnidadeEconomicaProperty;
    payload: any;
  }>;
  onClickDelete: (index: number) => void;
};

const ModalContent: React.FC<Props> = ({ empresas, form, dispatch, onClickDelete }: Props) => {
  const setEmpresa = (payload: any, index: number, property: string) => {
    const arr = [...form.empresas];
    const empresa = arr[index];
    empresa[property] = payload;
    dispatch({ type: 'SET_EMPRESAS', payload: arr });
  };

  const onClickAdd = () => {
    const arr = [...form.empresas];
    arr.push({
      codigoUnidadeEconomica: '',
      empresaId: 0,
      ativo: true,
      principal: false,
    });
    dispatch({ type: 'SET_EMPRESAS', payload: arr });
  };

  const camposRelacoes = useMemo(() => {
    const empresasOptions = empresas.map(e => ({ label: e.razaoSocial, value: e.id }));
    const arr: any[] = [];
    form.empresas.forEach((item: any, i: number) => {
      const empresaState = empresasOptions.find(e => e.value === item.empresaId);
      arr.push(
        <SingleSelect
          state={[empresaState, option => setEmpresa(option?.value, i, 'empresaId')]}
          options={empresasOptions}
          isClearable={false}
          placeholder="Empresa"
        />,
      );
      arr.push(
        <Input
          state={[
            form.codigoUnidadeEconomica,
            (value: React.SetStateAction<string>) => setEmpresa(value, i, 'codigoUnidadeEconomica'),
          ]}
          label="Unidade econômica"
        />,
      );
      const ativoCheckbox = (
        <Checkbox label="Ativo" checked={item.ativo} onClick={() => setEmpresa(!item.ativo, i, 'ativo')} />
      );
      const addButton = (
        <IconButton onClick={onClickAdd}>
          <Add />
        </IconButton>
      );
      const removeButton = (
        <IconButton onClick={() => onClickDelete(i)}>
          <Delete />
        </IconButton>
      );
      if (form.empresas.length === i + 1) {
        arr.push(
          <>
            {ativoCheckbox}
            <Checkbox
              label="Principal"
              checked={item.principal}
              onClick={() => setEmpresa(!item.principal, i, 'principal')}
            />
            {addButton}
            {removeButton}
          </>,
        );
      } else {
        arr.push(
          <>
            {ativoCheckbox}
            <Checkbox
              label="Principal"
              checked={item.principal}
              onClick={() => setEmpresa(!item.principal, i, 'principal')}
            />
            {removeButton}
          </>,
        );
      }
    });
    return arr;
  }, [form.empresas]);

  return <Form items={camposRelacoes} columns={4} />;
};

export default ModalContent;
