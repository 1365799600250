import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { MoreVert, Person } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import * as S from './styles';
import * as T from './types';
import api from '../../../_core/api';
import { AuthContext } from '../../../_main/contexts/auth';
import { failure, handleErrors, success, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { requests } from '../../../requests';
import { useCache } from '../../../_core/cache';
import { Button, Form, Input, Modal, Option, Select, Table } from '../../../_core/_components';
import { ModalContent } from './modal-content';

const CODIGOS_SERASA = new Map<string, string[]>([
  ['/analisar-negativacao', ['PENDENTE_RECOMENDACAO_EQUIPE_CORPORATIVA', 'PENDENTE_RECOMENDACAO_EQUIPE_SHOPPING']],
  [
    '/aprovar-negativacao',
    ['PENDENTE_APROVACAO_SUPERINTENDENTE_HOLDING', 'PENDENTE_APROVACAO_SUPERINTENDENTE_SHOPPING'],
  ],
]);

export const AprovarNegativacao: React.FC = () => {
  const { response: responseGruposCobranca, isLoading: isLoadingGruposCobranca } = useCache({
    key: 'getGruposCobranca',
    fn: requests.get('getGruposCobranca'),
  });
  const { response: responsePortfolios, isLoading: isLoadingPortfolios } = useCache({
    key: 'getPortfolios',
    fn: requests.get('getPortfolios'),
  });
  const { response: responseJustificativas, isLoading: isLoadingJustificativas } = useCache({
    key: 'getJustificativas',
    fn: requests.get('getJustificativas'),
  });
  const location = useLocation();
  const pageRef = useRef<number>();
  const { id, profile, shoppings: profileShoppings } = useContext(AuthContext);
  const [fiadores, setFiadores] = useState<T.Fiador[]>([]);
  const [justificativas, setJustificativas] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [marca, setMarca] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [luc, setLuc] = useState('');
  const [nrBoleto, setNrBoleto] = useState('');
  const [shoppingsList, setShoppingsList] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [portfolio, setPortfolio] = useState<Option[]>([]);
  const [portifolioList, setPortifolioList] = useState<Option[]>([]);
  const [grupoCobranca, setGrupoCobranca] = useState<Option[]>([]);
  const [grupoCobrancaList, setGrupoCobrancaList] = useState<Option[]>([]);
  const [customerInSerasa] = useState(false);
  const [customerInOutSerasa] = useState(false);
  const [customerNeverSerasa] = useState(false);
  const [data, setData] = useState<T.Response[]>([]);
  const [values, setValues] = useState<T.Values[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const title = location.pathname.includes('analisar') ? 'Analisar' : 'Aprovar';

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setSelectedShoppings(listShopping);
      setShoppingsList(listShopping);
    }
  }, [profileShoppings]);

  useEffect(() => {
    if (responseGruposCobranca !== undefined) {
      const listGrupoCobranca: Option[] = [];
      responseGruposCobranca.data.content.forEach((item: any) => {
        listGrupoCobranca.push({
          value: item.idGrupoCobranca,
          label: item.nome,
        });
      });
      setGrupoCobrancaList(listGrupoCobranca);
      setGrupoCobranca(listGrupoCobranca);
    }
  }, [responseGruposCobranca]);

  useEffect(() => {
    if (responsePortfolios !== undefined) {
      const listPortfolios: Option[] = [];
      responsePortfolios.data.content.map((item: string) => {
        listPortfolios.push({
          value: item,
          label: item,
        });
        return null;
      });
      setPortfolio(listPortfolios);
      setPortifolioList(listPortfolios);
    }
  }, [responsePortfolios]);

  useEffect(() => {
    if (responseJustificativas !== undefined) {
      const listJustificativas: any[] = [];
      responseJustificativas.data.content.map((item: any) => {
        listJustificativas.push({
          value: item.idSerasaJustificatica,
          label: item.txtJustificativa,
          campoObrigatorio: item.campoObrigatorio,
        });
        return null;
      });
      listJustificativas.unshift({
        value: 999,
        label: 'Selecione',
        campoObrigatorio: '',
      });

      setJustificativas(listJustificativas);
    }
  }, [responseJustificativas]);

  const getGrupoCobranca = () => {
    if (grupoCobranca && grupoCobranca.length > 0) {
      return grupoCobranca.map(e => e.value);
    }

    return undefined;
  };

  const getShoppingIds = () => {
    return selectedShoppings.find(s => s.value === 'todos')
      ? shoppingsList.filter(s => s.value !== 'todos').map(e => e.value)
      : selectedShoppings.map(e => e.value);
  };

  const getFilters = async (page?: number) => {
    setLoading(true);
    setData([]);
    const paramAdicionais = ['COBRANÇA CORPORATIVA', 'APROVADOR HOLDING', 'HOLDING'].includes(profile);
    try {
      const url = location.pathname;
      const pageQueryString = page ? `?page=${page}` : '';
      const response = await api.post(`/workflow/serasa/serasa-negativacao/buscar/boletos-shopping${pageQueryString}`, {
        idShopping: getShoppingIds(),
        idUsuario: id,
        clientesNegativado: customerInSerasa,
        clientesForamNegativado: customerInOutSerasa,
        clientesNuncaNegativado: customerNeverSerasa,
        marca: marca.length > 0 ? marca : undefined,
        luc: luc.length > 0 ? luc : undefined,
        numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
        numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
        portifolio: paramAdicionais ? portfolio.map(e => e.label) : undefined,
        grupoCobranca: paramAdicionais ? getGrupoCobranca() : undefined,
        status: CODIGOS_SERASA.get(url),
        url: location.pathname,
      });
      if (_.isEmpty(response.data.content)) {
        setData([]);
        warning('Não obteve resultados.');
      } else {
        setValues(
          response.data.content.map((item: T.Response) => ({
            id: item.idBoleto,
            negativado: item.flagNegativar,
            fiadores: item.fiadores.map(fiador => ({
              id: fiador.usuarioCadastro.id,
              label: fiador.usuarioCadastro.nome,
            })),
            valTotalVencido: item.valTotalVencido,
            justificativa: item.serasaJustificatica?.idSerasaJustificatica ?? 999,
            observacao: item.txtObservacao ?? '',
            nrChamado: item.numChamado ?? '',
            anexo: item.nomeAnexo ?? '',
          })),
        );
        setData(response.data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onModalSubmit = async () => {
    setLoading(true);
    if (selectedValue === undefined) return;
    try {
      if (selectedValue.file) {
        const formData = new FormData();
        formData.append('file', selectedValue.file);
        formData.append('idBoleto', selectedValue.id.toString());
        formData.append('idJustificativa', selectedValue.justificativa.toString());
        formData.append('idUsuario', id.toString());
        formData.append('numChamado', selectedValue.nrChamado);
        formData.append('txtObservacao', selectedValue.observacao);
        await api.post('/workflow/serasa/serasa-upload/historico/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        const payload = {
          idBoleto: selectedValue.id,
          idJustificativa: selectedValue.justificativa,
          idUsuario: id,
          numChamado: selectedValue.nrChamado,
          txtObservacao: selectedValue.observacao,
        };
        await api.post('/workflow/serasa/serasa-workflow/salvar-historico', payload);
      }
      setSelectedValue(undefined);
      await getFilters();
      setLoading(false);
      success('Salvo com sucesso.');
    } catch (error) {
      setLoading(false);
      handleErrors(error);
    }
  };

  const submitNegative = async () => {
    let disabled = false;
    let boletoId = 0;
    values.forEach(e => {
      if (!e.negativado && (!e.justificativa || e.justificativa === 999)) {
        disabled = true;
        boletoId = e.id;
      }
    });
    if (disabled) {
      const boleto = data.find(e => e.idBoleto === boletoId);
      return failure(`O boleto ${boleto?.numBoleto} precisa de uma justificativa.`);
    }
    setLoading(true);
    try {
      const boletos = data.map((item, i) => ({
        idBoleto: item.idBoleto,
        loja: {
          idSerasaLoja: item.serasaLoja.idSerasaLoja,
          negativar: values[i].negativado,
        },
        fiadores: item.fiadores.map(e => ({
          idSerasaBoletoFiador: e.idSerasaBoletoFiador,
          negativar: values[i].negativado,
        })),
      }));
      await api.post('/workflow/serasa/serasa-negativacao/submeter-recomendacao', {
        idUsuario: id,
        boletos,
      });
      await getFilters();
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
    setConfirmOpen(false);
    return null;
  };

  const limparObservacoes = async () => {
    setLoading(true);
    try {
      await api.post('/workflow/serasa/serasa-workflow/historico/limpar', {
        idBoleto: selectedValue.id,
        idUsuario: id,
      });
      setSelectedValue(undefined);
      await getFilters(pageRef.current);
      success('Submetido com sucesso.');
    } catch (error) {
      setSelectedValue(undefined);
      handleErrors(error);
    }
    setLoading(false);
  };

  const download = async () => {
    setLoading(true);
    const paramAdicionais = ['COBRANÇA CORPORATIVA', 'APROVADOR HOLDING', 'HOLDING'].includes(profile);
    try {
      const url = location.pathname;
      const response = await api.post(
        `/workflow/serasa/serasa-negativacao/download/boletos`,
        {
          idShopping: paramAdicionais ? profileShoppings?.map(e => e.id) : getShoppingIds(),
          idUsuario: id,
          clientesNegativado: customerInSerasa,
          clientesForamNegativado: customerInOutSerasa,
          clientesNuncaNegativado: customerNeverSerasa,
          marca: marca.length > 0 ? marca : undefined,
          luc: luc.length > 0 ? luc : undefined,
          numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
          numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
          portifolio: paramAdicionais ? portfolio.map(e => e.label) : undefined,
          grupoCobranca: paramAdicionais ? grupoCobranca.map(e => e.value) : undefined,
          status: CODIGOS_SERASA.get(url),
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const formItems = () => {
    const items = [
      <Input label="Marca" state={[marca, setMarca]} />,
      <Input label="CNPJ/CPF" state={[cnpj, setCnpj]} />,
      <Input label="LUC" state={[luc, setLuc]} />,
      <Input label="Número do boleto" state={[nrBoleto, setNrBoleto]} />,
    ];
    if (['APROVADOR HOLDING', 'COBRANÇA CORPORATIVA'].includes(profile)) {
      items.push(<Select state={[portfolio, setPortfolio]} options={portifolioList} placeholder="Portfólios" />);
      items.push(
        <Select
          state={[grupoCobranca, setGrupoCobranca]}
          options={grupoCobrancaList}
          placeholder="Grupos de cobrança"
        />,
      );
    } else {
      items.unshift(
        <Select
          todos
          placeholder="Shoppings"
          state={[selectedShoppings, profile.includes('SHOPPING') ? () => null : setSelectedShoppings]}
          options={shoppingsList}
          checkedByDefault
        />,
      );
    }
    return items;
  };

  const columns = useMemo(() => {
    const columns1 = [
      { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
      { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
      { label: 'Razão Social', key: 'nomRazaoSocial', orderable: true },
    ];
    const columns2 = [
      { label: 'LUC', key: 'luc', orderable: true },
      { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
      { label: 'Número do boleto', key: 'numBoleto', orderable: true },
      { label: 'Valor do Boleto', key: 'valTotalVencido', orderable: true },
      { label: 'Qtd de negativações', key: 'qtdNegativacao', orderable: true },
      { label: 'Qtd de exceções', key: 'quantidadeExcecao', orderable: true },
      { label: 'Negativar', key: 'negativar', orderable: false },
      { label: 'Fiadores', key: 'fiadores', orderable: false },
      { label: 'Justificativa', key: 'add', orderable: false },
    ];
    if (profile === 'COBRANÇA CORPORATIVA') {
      return [
        ...columns1,
        { label: 'Grupo de cobrança', key: 'grupoCobranca', orderable: true },
        { label: 'Portfolio', key: 'portfolio', orderable: true },
        ...columns2,
      ];
    }
    return [...columns1, ...columns2];
  }, [profile]);

  const renderSubItems = (item: T.Response) => {
    const arr = [];
    if (item.serasaJustificatica?.txtJustificativa) {
      arr.push(<b>Justificativa: </b>);
      arr.push(item.serasaJustificatica.txtJustificativa);
    }
    if (item.numChamado) {
      arr.push(<b>Nr. do chamado: </b>);
      arr.push(item.numChamado);
    }
    if (item.txtObservacao) {
      arr.push(<b>Observação: </b>);
      arr.push(item.txtObservacao);
    }
    return arr.length > 0 ? arr : undefined;
  };

  const findIdBoleto = (idBoleto: number) => {
    const boleto = data.find(e => e.idBoleto === idBoleto);
    if (boleto) {
      setSelectedValue({
        id: boleto.idBoleto,
        justificativa: boleto.serasaJustificatica?.idSerasaJustificatica ?? 999,
        observacao: boleto.txtObservacao ?? '',
        nrChamado: boleto.numChamado ?? '',
        anexo: boleto.nomeAnexo ?? '',
      });
    }
  };

  const renderedData = () => {
    return data.map(item => ({
      nmShopping: item.nmShopping,
      nomFantasia: item.nomFantasia,
      nomRazaoSocial: item.serasaLoja.nomRazaoSocial,
      luc: item.luc,
      datVencimento: item.datVencimento,
      numBoleto: item.numBoleto,
      valTotalVencido: item.valTotalVencido?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      qtdNegativacao: item.qtdNegativacao,
      quantidadeExcecao: item.quantidadeExcecao,
      grupoCobranca: item.nomGrupoCobranca,
      portfolio: item.nomPortifolio,
      fiadores: item.fiadores.length ? (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="primary"
          component="span"
          onClick={() => setFiadores(item.fiadores)}
        >
          <Person />
        </IconButton>
      ) : null,
      negativar: item.flagNegativar ? (
        <span style={{ backgroundColor: '#FFF6E8', color: '#E88D03', padding: 8, borderRadius: 4 }}>NEGATIVAR</span>
      ) : (
        <span style={{ backgroundColor: '#ECFBE6', color: '#038931', padding: 8, borderRadius: 4 }}>ISENTAR</span>
      ),
      add: (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="primary"
          component="span"
          onClick={() => findIdBoleto(item.idBoleto)}
        >
          <MoreVert />
        </IconButton>
      ),
      subItems: renderSubItems(item),
    }));
  };

  const modalContentFiador = useMemo(() => {
    const arrayInput: React.ReactNode[] = [];
    fiadores.forEach(fiador => {
      arrayInput.push(<Input label="Nome" state={[fiador.nomeFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="CPF/CNPJ" state={[fiador.cpfcnpjFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="Endereço" state={[fiador?.endereco ?? '', () => null]} disabled />);
    });
    return <Form items={arrayInput} />;
  }, [fiadores]);

  const isValid = useMemo(() => {
    if (selectedValue === undefined) return false;
    const { justificativa, nrChamado, file } = selectedValue;
    if (justificativa && justificativa !== 999) {
      const { campoObrigatorio } = justificativas.find((e: any) => e.value === justificativa);
      switch (campoObrigatorio) {
        case 'NAO_OBRIGATORIO':
          return true && justificativa;
        case 'CHAMADO':
          return !!nrChamado;
        case 'CHAMADO_OU_ANEXO':
          return !!nrChamado || file !== undefined;
        case 'ANEXO':
          return file !== undefined;
        default:
          return true && justificativa;
      }
    }
    return true;
  }, [selectedValue]);

  const confirmationModalText = (
    <S.Title>Deseja enviar os boletos apresentados em tela para a próxima etapa de aprovação do workflow?</S.Title>
  );

  return (
    <>
      <FullScreenLoading
        isEnabled={loading || isLoadingGruposCobranca || isLoadingJustificativas || isLoadingPortfolios}
      />
      <S.Container>
        <Form items={formItems()} submitButton={<Button onClick={() => getFilters()} text="Pesquisar" />} />
        {renderedData().length > 0 && (
          <>
            <Table columns={columns} data={renderedData()} pageRef={pageRef} />
            <S.Section>
              <Button text="Exportar" onClick={download} />
              <Button
                text={title === 'Aprovar' ? 'Aprovar Negativação' : 'Submeter Recomendação'}
                onClick={() => setConfirmOpen(true)}
              />
            </S.Section>
          </>
        )}
      </S.Container>
      {selectedValue && (
        <Modal
          title="Justificativa"
          onClose={() => setSelectedValue(undefined)}
          content={<ModalContent valuesState={[selectedValue, setSelectedValue]} justificativas={justificativas} />}
          actionItems={[
            <Button text="Limpar justificativa" onClick={limparObservacoes} />,
            <Button text="Salvar" onClick={onModalSubmit} disabled={!isValid} />,
          ]}
        />
      )}
      {fiadores.length > 0 && (
        <Modal title="Detalhes do Fiador" onClose={() => setFiadores([])} content={modalContentFiador} />
      )}
      {confirmOpen && (
        <Modal
          title="Confirmação"
          onClose={() => setConfirmOpen(false)}
          content={confirmationModalText}
          actionItems={[<Button text="Confirmar" onClick={submitNegative} />]}
        />
      )}
    </>
  );
};
